import axios from "axios";
import React, { useEffect, useState,useContext } from "react";
import { Link } from "react-router-dom";
import { baseUrl } from "../../../config/baseUrl";
import AddCategory from "../Category  not working/AddCategory";
import AddSubCatgory from "./AddSubCatgory";
import EditChemicalSubCat from "./EditChemicalSubCat";
import DeleteToast from "../../Users/DeleteToast";
import { ToastMessgae } from "../../utils/toast";
import { toast } from "react-toastify";
import loaderContext from "../../../context/LoaderContext";

const ChemiclSubCatList = () => {
  const [subCatList, setSubCatList] = useState([]);
  let user=JSON.parse(localStorage.getItem("user"))
  const [values, setValues] = useState({
    depo: [],
    category: "",
    subcategory_name: "",
    desc: "",
  });
  let [deleteid, setDeleteid] = useState();
  let [apicall, setApiCall] = useState(4234);
  const [currentImgFile, setCurrentImgFile] = useState(null);
  let {loader,setLoader}=useContext(loaderContext)
  useEffect(() => {
    setLoader(true)
    fetchSubCategory();
  }, [apicall]);
  // fetch cat api
  const fetchSubCategory = async () => {
    try {
      const res = await axios.get(
        `${baseUrl}/module/admin/subcategory-viewset`,
        {
          headers: {
            Authorization: "Token " + localStorage.getItem("token"),
          },
        }
      );
      console.log("sub category", res.data.data);
      setSubCatList(res.data.data);
    } catch (error) {
      console.log("fetch sub category error", error);
    }finally{
        setLoader(false)
    }
  };

  const editApi = async (id) => {
    try {
      const res = await axios.get(
        `${baseUrl}/module/admin/subcategory-viewset/${id}`,
        {
          headers: {
            Authorization: "Token " + localStorage.getItem("token"),
          },
        }
      );
      
      setValues({
        ...res.data.data,
      });
      setCurrentImgFile(res.data.data.subcategory_document);
      if (res?.data?.code === 200) {
        let unique = document.getElementById("openEditModelBox");
        unique.click(); 
      } else {
        toast.error(res?.data?.message);
      }
    } catch (error) {
      console.log("edit subcat error", error);
    }
  };

  let deleteHandle = (id) => {
    (async () => {
        setLoader(true)
      let response = await axios(
        `${baseUrl}/module/admin/subcategory-viewset/${id}`,
        {
          method: "DELETE",
          headers: {
            Authorization: "Token " + localStorage.getItem("token"),
          },
        }
      );
      //   setSubCatList((prvVal) => {
      //     prvVal = prvVal.filter((arr) => arr.id !== response?.data?.data?.id);
      //     return [...prvVal];
      //   });
      if (response?.data?.code === 200) {
        fetchSubCategory();
        ToastMessgae(response?.data);
      } else {
        fetchSubCategory();
        ToastMessgae(response.data);
      }
    })();
  };

  return (
    <>
      {loader ? (
        <div className={`${loader ? "loader-main" : ""}`}>
          <div className={`${loader ? "loader" : ""}`}></div>
        </div>
      ) : (
        <>
          {" "}
          <section className="main-sec">
            <div className="row">
              <div className="col-lg-12">
                <div className="dashboard-title">
                  <h4 className="dash-head">
                    <i className="fa fa-chart-bar me-2" />
                    Chemical List
                  </h4>
                {user?.user_type == "SUPER_ADMIN" || user?.user_type == "ADMIN_MANAGER" &&  <Link
                    to="#"
                    className="blu-btn"
                    data-bs-toggle="modal"
                    data-bs-target="#addbus2"
                  >
                    <i className="fa fa-plus me-2" />
                    Add
                  </Link>}
                </div>
                <div className="custom-bredcump">
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to="/">Dashboard</Link>
                      </li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        {/* Sub Category List */}
                        Chemical List
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="cards bus-list">
                  {/* <div className="bus-filter">
                                <div className="row justify-content-end">
                                    <div className="col-lg-8">
                                        <form action="">
                                            <div className="row justify-content-end">
                                                
                                                <div className="col-lg-4">
                                                <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Enter sub category"
                                                        name="subcategory_name"
                                                        // value={values.subcategory_name}
                                                        // onChange={handleChange}
                                                    />
                                                </div>
                                                <div className="col-lg-2">
                                                    <button
                                                        className="thm-btn w-100"
                                                    // onClick={handleSearch}
                                                    >
                                                        Search
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div> */}
                  <div className="table table-responsive custom-table">
                    <table className="table table-borderless">
                      <thead>
                        <tr>
                          <th> Sr No.</th>
                          <th>image</th>
                          {/* <th>depo name</th> */}
                          {/* <th>category name</th> */}
                          <th>Chemical name</th>
                          <th>
                            {" "}
                            <span>Depot &nbsp; - &nbsp; Chemical quantity 
                              </span>{" "}
                          </th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {subCatList.map((cat, index) => {
                          return (
                            <tr key={cat.id}>
                              <td>
                                <span>{index + 1}</span>
                              </td>
                             
                              <td>
                                <img
                                  src={cat.subcategory_images}
                                  width={100}
                                  height={100}
                                />
                              </td>
                              {/* <td><span>{cat?.category_name}</span></td> */}
                              <td>
                                <span>{cat?.subcategory_name}</span>
                              </td>
                              <td>
                                {cat?.depo.map((arr) => (
                                  <div>
                                    <span>
                                      {" "}
                                      {arr.depo_name} &nbsp; - &nbsp; {arr.q} {" "} L
                                    </span>
                                  </div>
                                ))}
                              </td>
                              <td>
                                {/* {(loginUser.user_type === "ADMIN_MANAGER" || loginUser?.user_type === "CLEANING_MANAGER") && ( */}
                                <Link
                                  className="btn btn-outline-warning"
                                  // data-bs-toggle="modal"
                                  // data-bs-target="#editsubcat"
                                  onClick={() => editApi(cat?.id)}
                                >
                                  <i className="fa fa-edit" />
                                </Link>
                                <button data-bs-toggle="modal"
                                  data-bs-target="#editsubcat"
                                  id="openEditModelBox" style={{display:"none"}}></button>
                                {/* )} */}
                                &nbsp;
                                <button
                                  className="btn btn-outline-danger"
                                  type="button"
                                  data-bs-toggle="modal"
                                  data-bs-target="#pupup"
                                  onClick={() => setDeleteid(cat?.id)}
                                >
                                  <i className="fa fa-trash" />
                                </button>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>

                    {/* <Pagination
                  pageNumber={pageNumber}
                  setPageNumber={setPageNumber}
                  pageLimit={pageLimit}
                  paginationDetails={paginationDetails}
                /> */}
                  </div>
                </div>
              </div>
            </div>
          </section>
          <AddSubCatgory setApiCall={setApiCall} setLoader={setLoader} />
          <EditChemicalSubCat
            values={values}
            setValues={setValues}
            currentImgFile={currentImgFile}
            setApiCall={setApiCall}
            setLoader={setLoader}
          />
          <DeleteToast deleteHandle={deleteHandle} id={deleteid} />;
        </>
      )}
    </>
  );
};

export default ChemiclSubCatList;
